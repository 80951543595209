import QRCode from "react-qr-code";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="url" element={<AndroidURL />} />
        <Route index path="*" element={<InternalData />} />
      </Routes>
    </div>
  );
}

const AndroidURL = () => {
  let windowLocation =
    "https://play.google.com/apps/internaltest/4698966654702512426";
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ background: "white", padding: "16px" }}>URL</div>
      <QRCode value={windowLocation} />
      <div style={{ padding: "16px" }}>WAG URL ANDROID</div>
      <button onClick={() => copyToClip(windowLocation)}>
        Copy URL to Clipboard
      </button>
    </div>
  );
};

const InternalData = () => {
  let windowLocation = window.location.href;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ background: "white", padding: "16px" }}>
        <QRCode value={windowLocation} />
      </div>
      <div style={{ padding: "16px" }}>{windowLocation}</div>
      <button onClick={() => copyToClip(windowLocation)}>
        Copy URL to Clipboard
      </button>
    </div>
  );
};

function copyToClip(windowLocation) {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", windowLocation);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = windowLocation;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

export default App;
